import { Box } from '@mui/material'
import { formatPrice, NumberType } from '@uniswap/conedison/format'
import { Currency, Price } from '@uniswap/sdk-core'
import Column from 'components/Column'
import { OpacityHoverState } from 'components/Common'
import Row, { AutoRow } from 'components/Row'
import { Input } from 'components/Settings/Input'
import { useEthPriceUSD, useGraphTokenListMAP } from 'hooks/useQueryLiquidity'
import { useMemo } from 'react'
import { usePositionSwapActionHandlers, useTradeSWAPState } from 'state/positionSwap/hooks'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN } from 'utils/bn'

import { EDITPROPS, TabPeriod } from './types'
import { countZeros } from 'utils/countZeros'

const Wrapper = styled(Column)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.tradeSectionBorder};
  background-color: ${({ theme }) => theme.swapSectionBg};
  border-radius: 16px;
  padding: 8px 16px 16px;
  margin-bottom: 8px;
  align-items: flex-start;
`
const StyledFilter = styled.div`
  display: flex;
  background: ${({ theme }) => theme.backgroundSelector};
  border-radius: 20px;
`
const StyledSelector = styled.div<{ active: boolean }>`
  padding: 8px;
  border-radius: 20px;
  background: ${({ active, theme }) => (active ?  '#f47226'
 : 'none')};
  cursor: pointer;
  ${OpacityHoverState}
`
const StyledSelectorText = styled(ThemedText.SubHeader)<{ active: boolean }>`
  color: ${({ theme, active }) => (active ? theme.textPrimary : theme.textSecondary)};
`
export type TabPRPS = {
  label: string
  value: TabPeriod | EDITPROPS
}

type TradeTypeProps = {
  data?: TabPRPS[]
  onChange?: (_value: TabPeriod | EDITPROPS) => void
  tabPeriod?: string
  setTabPeriod?: any
}

export function TradeTypes({ data, onChange, tabPeriod, setTabPeriod }: TradeTypeProps) {
  const tabOptions: { label: string; value: TabPeriod | EDITPROPS }[] = data
    ? data
    : [
        { label: 'Market', value: TabPeriod.Market },
        { label: 'Limit', value: TabPeriod.Limit },
      ]
  const setChange = (tpr: TabPeriod | EDITPROPS) => {
    onChange && onChange(tpr)
    setTabPeriod(tpr)
  }

  return (
    <StyledFilter>
      {tabOptions.map((tabOption) => {
        return (
          <StyledSelector
            id={tabOption.value}
            key={tabOption.value}
            active={tabOption.value === tabPeriod}
            onClick={() => setChange(tabOption.value)}
          >
            <StyledSelectorText lineHeight="20px" active={tabOption.value === tabPeriod}>
              {tabOption.label}
            </StyledSelectorText>
          </StyledSelector>
        )
      })}
    </StyledFilter>
  )
}

const StyledInput = styled(Input)`
  font-size: 28px;
  font-weight: 500;
  color: ${({ theme }) => theme.textPrimary};
  text-align: left;
  &::placeholder {
    font-size: 28px;
    font-weight: 500;
    color: ${({ theme }) => theme.textPrimary};
  }
`

type SwapDetailsProps = {
  price?: Price<Currency, Currency>
  showInverted?: number
  setLimitPrice: (val: string) => void
  limitPrice: string
}

export default function SwapDetails({ price, showInverted, setLimitPrice, limitPrice }: SwapDetailsProps) {
  // const theme = useTheme()
  // const [showInverted, setShowInverted] = useState<boolean>(false)
  const { onsetIsLimit } = usePositionSwapActionHandlers()
  const { isToken0, poolInfo, isLimit } = useTradeSWAPState()

  const tabPeriod = isLimit ? TabPeriod.Limit : TabPeriod.Market

  const InputChange = (e: any) => {
    setLimitPrice && setLimitPrice(e.target.value)
  }

  const setTabPeriod = (val: TabPeriod) => {
    const da = val == TabPeriod.Limit

    onsetIsLimit(da)
  }
  const showInverteds = isToken0
  // const { baseCurrency, quoteCurrency } = price ?? {}
  const formattedPrice = useMemo(() => {
    if (!price) return '0'
    try {
      return countZeros(showInverteds ? price.toSignificant() : price.invert().toSignificant())
    } catch {
      return '0'
    }
  }, [price, showInverteds])
  const ethprice = useEthPriceUSD()
  const tokenPrice = useGraphTokenListMAP()

  // const { data: usdPrice } = useUSDPriceByToken(tryParseCurrencyAmount('1', showInverteds ? baseCurrency : quoteCurrency))

  const label = showInverteds ? `${price?.quoteCurrency?.symbol ?? ''}` : `${price?.baseCurrency?.symbol ?? ''} `
  const labelInverted = showInverteds
    ? `${price?.baseCurrency?.symbol ?? ''} `
    : `${price?.quoteCurrency?.symbol ?? ''}`
  // const flipPrice = useCallback(() => setShowInverted(!showInverted), [setShowInverted, showInverted])

  const prices = useMemo(() => {
    if (!price || !ethprice || !tokenPrice) return
    let token
    if (showInverteds) {
      token = price.baseCurrency
    } else {
      token = price.quoteCurrency
    }

    const tokenusd = tokenPrice[token.wrapped.address.toLocaleLowerCase()]

    const currentPrice = BN(tokenusd?.derivedETH || 0)
      .times(1)
      .times(ethprice)
      .toFixed()
    return currentPrice
  }, [ethprice, price, showInverteds, tokenPrice])
  return (
    <Wrapper>
      <AutoRow justify="space-between">
        <ThemedText.BodySmall color="color84">
          1 {labelInverted}= (${countZeros(prices) || 0})
        </ThemedText.BodySmall>
        <TradeTypes tabPeriod={tabPeriod} setTabPeriod={setTabPeriod} />
      </AutoRow>
      <Column align="flex-start" gap="4px">
        {!isLimit && (
          <ThemedText.BodySmall color="textPrimary" fontSize={28} fontWeight={500}>
            <Row align="center" gap="23px">
              <Box>{formattedPrice ?? '0'}</Box>
              <Box>{label}</Box>
            </Row>
          </ThemedText.BodySmall>
        )}
        {isLimit && (
          <ThemedText.BodySmall color="textPrimary" fontSize={28} fontWeight={500}>
            <Row align="center" gap="4px">
              <Box width="40%">
                <StyledInput
                  type="number"
                  className="limit-price-input flex-1"
                  value={limitPrice}
                  placeholder="0"
                  onChange={InputChange}
                />
              </Box>
              <>{label}</>
            </Row>
          </ThemedText.BodySmall>
        )}
      </Column>
    </Wrapper>
  )
}
