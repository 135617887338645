import { Box } from '@mui/material'
import { Currency } from '@uniswap/sdk-core'
import { ReactComponent as StarIconActive } from 'assets/imgs/star_active.svg'
import { StyledStarIcon } from 'components/Icons/StyledIcon'
import { MediumLoadingBubble } from 'components/Loading'
import { scrollbarStyle } from 'components/SearchModal/CurrencyList/index.css'
import { AllPoolsProps } from 'hooks/useQueryAllPools'
import { useAtomValue } from 'jotai/utils'
import { CSSProperties, MutableRefObject, useCallback, useMemo } from 'react'
import { Check } from 'react-feather'
import { FixedSizeList } from 'react-window'
import styled from 'styled-components/macro'
import { fromSqrt96Wei } from 'utils/bn'
import { countZeros } from 'utils/countZeros'

import { WrappedTokenInfo } from '../../../state/lists/wrappedTokenInfo'
import { ThemedText } from '../../../theme'
import Row, { RowBetween, RowFixed } from '../../Row'
import { searchRtarList, useRtarAcitons } from '../state'
function currencyKey(currency: AllPoolsProps): string {
  return currency.id
}

const CheckIcon = styled(Check)`
  height: 20px;
  width: 20px;
  margin-left: 4px;
  color: ${({ theme }) => theme.accentAction};
`

const ListWrapper = styled.div`
  /* padding-right: 0.25rem; */
`

const MenuItem = styled(RowBetween)<{ dim?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 100px 1fr;
  grid-gap: 8px;
  padding: 6px 16px;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  opacity: ${({ disabled, selected, dim }) => (dim || disabled || selected ? 0.4 : 1)};
  /* border-bottom: 1px dashed ${({ theme }) => theme.backgroundInteractive}; */
  :hover {
    background-color: ${({ theme }) => theme.backgroundHover};
  }
`

const StyledFeeBox = styled(Box)`
  margin-left: 4px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  background-color: ${({ theme }) => theme.backgroundInteractive};
`
const StyledMaxLevageBox = styled(Box)`
  margin-left: 8px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light-White100, #fff);
  padding: 2px 4px;
  border-radius: 2px;
  background: var(--light-primary, #f47226);
`

function CurrencyRow({
  currency,
  onSelect,
  isSelected,
  otherSelected,
  style,
  showCurrencyAmount,
  id,
}: {
  currency: AllPoolsProps
  onSelect: (hasWarning: boolean) => void
  isSelected: boolean
  otherSelected: boolean
  style?: CSSProperties
  showCurrencyAmount?: boolean
  id: string
}) {
  const key = currencyKey(currency)
  // const warning = currency.isNative ? null : checkWarning(currency.address)
  const warning = null
  // const isBlockedToken = !!warning && !warning.canProceed
  const isBlockedToken = false
  const blockedTokenOpacity = '0.6'
  // only show add or remove buttons if not on selected list

  const dataRart = useAtomValue(searchRtarList)
  const { updataList, rmDataList } = useRtarAcitons()
  return (
    <MenuItem
      tabIndex={0}
      style={style}
      className={`token-item-${key}`}
      // onKeyPress={(e: { key: string }) => (!isSelected && e.key === 'Enter' ? onSelect(!!warning) : null)}
      onClick={() => (isSelected ? null : onSelect(!!warning))}
      disabled={isSelected}
      selected={otherSelected}
      dim={isBlockedToken}
    >
      <Row gap="sm" style={{ opacity: isBlockedToken ? blockedTokenOpacity : '1' }}>
        <Box display="flex" alignItems="center">
          {dataRart[id] ? (
            <StarIconActive
              onClick={(e) => {
                e.stopPropagation()
                rmDataList(id)
              }}
            />
          ) : (
            <StyledStarIcon
              onClick={(e) => {
                e.stopPropagation()
                updataList(id)
              }}
            />
          )}
          <Row>
            <ThemedText.TextPrimary fontWeight={700} ml="4px">
              {currency.token0.symbol}
            </ThemedText.TextPrimary>
            <ThemedText.TextSecondary fontSize="14px" fontWeight={700}>
              /{currency.token1.symbol}
            </ThemedText.TextSecondary>
            <StyledMaxLevageBox>
              <ThemedText.TextPrimary fontSize="12px" fontWeight={400}>
                {currency.maxLevleage}x
              </ThemedText.TextPrimary>
            </StyledMaxLevageBox>
            <StyledFeeBox>
              <ThemedText.TextPrimary fontSize="12px" fontWeight={400}>
                {currency.fee.toSignificant()}%
              </ThemedText.TextPrimary>
            </StyledFeeBox>
          </Row>
        </Box>
      </Row>
      <ThemedText.TextSecondary fontSize="12px" fontWeight={700}>
        {countZeros(fromSqrt96Wei(currency.sqrtPrice, currency.token0.decimals, currency.token1.decimals).toFixed())}
      </ThemedText.TextSecondary>
      <Row gap="sm" justify="flex-end">
        {showCurrencyAmount ? (
          <RowFixed>
            <ThemedText.TextPrimary
              fontSize="12px"
              fontWeight={700}
              color={Number(currency.change) >= 0 ? 'long' : 'short'}
            >
              {currency.change}%
            </ThemedText.TextPrimary>
          </RowFixed>
        ) : (
          isSelected && (
            <RowFixed>
              <CheckIcon />
            </RowFixed>
          )
        )}
      </Row>
    </MenuItem>
  )
}

interface TokenRowProps {
  data: Array<AllPoolsProps>
  index: number
  style: CSSProperties
}

const LoadingRow = () => (
  <MenuItem data-testid="loading-rows">
    <MediumLoadingBubble width="100px" />
    <MediumLoadingBubble width="100px" />
    <Row justify="flex-end" style={{ flex: 1 }}>
      <MediumLoadingBubble width="100px" />
    </Row>
  </MenuItem>
)

export default function CurrencyList({
  height,
  currencies,
  onCurrencySelect,
  fixedListRef,
  showCurrencyAmount,
  isLoading,
}: {
  height: number
  currencies?: AllPoolsProps[]
  otherListTokens?: WrappedTokenInfo[]
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: AllPoolsProps) => void
  otherCurrency?: Currency | null
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>
  showCurrencyAmount?: boolean
  isLoading: boolean
  searchQuery: string
  isAddressSearch: string | false
}) {
  const itemData = useMemo(() => {
    return currencies ?? []
  }, [currencies])

  const Row = useCallback(
    function TokenRow({ data, index, style }: TokenRowProps) {
      const row: AllPoolsProps = data[index]
      const currency = row
      // const isSelected = Boolean(currency && selectedCurrency && selectedCurrency.equals(currency))
      // const otherSelected = Boolean(currency && otherCurrency && otherCurrency.equals(currency))
      const handleSelect = () => currency && onCurrencySelect(currency)

      if (isLoading) {
        return LoadingRow()
      } else if (currency) {
        return (
          <CurrencyRow
            style={style}
            currency={currency}
            isSelected={false}
            id={row.id.toLocaleLowerCase()}
            onSelect={handleSelect}
            otherSelected={false}
            showCurrencyAmount={showCurrencyAmount}
          />
        )
      } else {
        return null
      }
    },
    [isLoading, onCurrencySelect, showCurrencyAmount]
  )

  const itemKey = useCallback((index: number, data: typeof itemData) => {
    const currency = data[index]
    return currencyKey(currency)
  }, [])

  return (
    <ListWrapper data-testid="currency-list-wrapper">
      {isLoading ? (
        <FixedSizeList
          className={scrollbarStyle}
          height={height}
          ref={fixedListRef as any}
          width="100%"
          itemData={[]}
          itemCount={10}
          itemSize={32}
        >
          {LoadingRow}
        </FixedSizeList>
      ) : (
        <FixedSizeList
          className={scrollbarStyle}
          height={height}
          ref={fixedListRef as any}
          width="100%"
          itemData={itemData}
          itemCount={itemData.length}
          itemSize={32}
          itemKey={itemKey}
          // style={{ height: 'auto !important' }}
        >
          {Row}
        </FixedSizeList>
      )}
    </ListWrapper>
  )
}
