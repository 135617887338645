import { ChainId, SUPPORTED_CHAINS, SupportedChainsType } from './chains'

type AddressMap = { [chainId: number]: string }

type ChainAddresses = {
  factoryAddress: string
  multicallAddress: string
  quoterAddress: string
  orderBookAddress?: string
  nonfungiblePositionManagerAddress?: string
  tickLensAddress?: string
  swapRouterAddress?: string
  v1MixedRouteQuoterAddress?: string
  lockAddress?: string
  tradeRouter?: string
  voterAddress?: string
  rougexTokenAddress?: string
  faucetAddress?: string
  minterAddress?: string
  tradeReaderAddress?: string
  rewardsDistributorAddress?: string
  roxUtilsAddress?: string
  PosnReader?: string
}

const DEFAULT_NETWORKS = [ChainId.ARBITRUM_ONE]

function constructSameAddressMap(address: string, additionalNetworks: ChainId[] = []): AddressMap {
  return DEFAULT_NETWORKS.concat(additionalNetworks).reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = address
    return memo
  }, {})
}
/*
   "WETH" :  "0x7507c1dc16935B82698e4C63f2746A2fCf994dF8",
    "WBERA" : "0x7507c1dc16935B82698e4C63f2746A2fCf994dF8",
    "rougeX" : "0x05956a4674945DEE1E00442a53c562350282C340",
    "AlgebraInterfaceMulticall" : "0xf26Bd9BA435395F26634C9Be5b717C6d10675897",
    "RoguexFactory" : "0xdf119e932877E61e5C2DB627C70130D37E45814C",
    "RoxUtils" : "0x5B0b4b97edb7377888E2c37268c46E28f5BD81d0",
    "PerpUtils" : "0x9C6C26F5D308B22254a57a87744E0c018BE20dC7",
    "RoxSpotPoolDeployer" : "0xDa8B685ceB09D7cb990CE155a3eA91b7D047e033",
    "RoxPerpPoolDeployer" : "0xF22FF8f7f430a2d07efB88015853D52C88BC286d",
    "RoxPosnPoolDeployer" : "0x8F833d4314377542107E79bf7005374A42c4499D",
    "NonfungiblePositionManager" : "0x386d7bE5793B061F01601F606f28E1017Cbc9E68",
    "Quoter" : "0x37e33B250985FAf4DBdc607FA7454CA76B883A2a",
    "TickLens" : "0xE8480B6bF4d0b5B2BE95cc41eCC14a98d528215b",
    "PerpRouter" : "0x859997D5686486b023b4fC375f6356FEDf40A923",
    "SwapRouter" : "0xdD84404e9741fB1Ba2f52906B25126fAE22b4243",
    "PerpOrderbook" : "0x8e7a5187117F3dfbae028ac26db00A75aE41F095",
    "VeArtProxy" : "0x648AA4e686F538e77EF0DEba5362009483EDC5cb",
    "VotingEscrow" : "0xd4c4Ae8172253332F4D5E13aAFC096cF35f33905",
    "RewardsDistributor" : "0xE962D867D5b6b06b9c1a68A625d2ea44b4378D21",
    "MasterChefFactory" : "0x84f7Df1129B8cD21ab4920BE6EaD959E33Ed6b86",
    "SwapMinningFactory" : "0xC37B383B80cd1E29136FCbC54dE77373ccA96479",
    "VotingRewardFactory" : "0xC1D22E673fbCA772BE2c5EbB86e7Eac72C91d885",
    "Voter" : "0x03245900162B798726514d9c3c18c1C8EfF2952f",
    "Minter" : "0x5506Fa71d84585acD4A06AD5bFcb1e5F6d876967",
    "DisFeeReward" : "0x7F4701CF364F0481d37272590CbB5F809C8c3B0F",
    "HypervisorFactory" : "0x662267CaA600d9f284356075C3504E78408A819B",
    "Reader" : "0xcd16b287536FF7A761B3574066a91e95635481ec",
    "PosnReader" : "0xee75742daFE25220337009949c0C529f56904151",
*/
const BERA_TEST_ADDRESSES: ChainAddresses = {
  factoryAddress: '0xdf119e932877E61e5C2DB627C70130D37E45814C',
  tradeRouter: '0x859997D5686486b023b4fC375f6356FEDf40A923',
  nonfungiblePositionManagerAddress: '0x386d7bE5793B061F01601F606f28E1017Cbc9E68',
  swapRouterAddress: '0xdD84404e9741fB1Ba2f52906B25126fAE22b4243',
  quoterAddress: '0x37e33B250985FAf4DBdc607FA7454CA76B883A2a',
  tickLensAddress: '0xE8480B6bF4d0b5B2BE95cc41eCC14a98d528215b',
  roxUtilsAddress: '0x5B0b4b97edb7377888E2c37268c46E28f5BD81d0',
  tradeReaderAddress: '0xcd16b287536FF7A761B3574066a91e95635481ec',
  PosnReader: '0xee75742daFE25220337009949c0C529f56904151',
  orderBookAddress: '0x8e7a5187117F3dfbae028ac26db00A75aE41F095',

  multicallAddress: '0xf26Bd9BA435395F26634C9Be5b717C6d10675897',

  lockAddress: '0xd4c4Ae8172253332F4D5E13aAFC096cF35f33905',
  rougexTokenAddress: '0x05956a4674945DEE1E00442a53c562350282C340',
  rewardsDistributorAddress: '0xE962D867D5b6b06b9c1a68A625d2ea44b4378D21',

  voterAddress: '0x03245900162B798726514d9c3c18c1C8EfF2952f',
  minterAddress: '0x5506Fa71d84585acD4A06AD5bFcb1e5F6d876967',
}

const CHAIN_TO_ADDRESSES_MAP: Record<SupportedChainsType, ChainAddresses> = {
  [ChainId.ARBITRUM_ONE]: BERA_TEST_ADDRESSES,
  [ChainId.GOERLI]: BERA_TEST_ADDRESSES,
  [ChainId.ARBITRUM_SEPOLIA]: BERA_TEST_ADDRESSES,
  [ChainId.BLAST_TEST]: BERA_TEST_ADDRESSES,
  [ChainId.BLAST_MAIN]: BERA_TEST_ADDRESSES,
  [ChainId.ZIRCUIT_TEST]: BERA_TEST_ADDRESSES,
  [ChainId.ZIRCUIT_MAIN]: BERA_TEST_ADDRESSES,
  [ChainId.BERA_TEST]: BERA_TEST_ADDRESSES,
}

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].factoryAddress
    return memo
  }, {}),
}

export const MULTICALL_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].multicallAddress
    return memo
  }, {}),
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {}

export const QUOTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].quoterAddress
    return memo
  }, {}),
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const nonfungiblePositionManagerAddress = CHAIN_TO_ADDRESSES_MAP[chainId].nonfungiblePositionManagerAddress
    if (nonfungiblePositionManagerAddress) {
      memo[chainId] = nonfungiblePositionManagerAddress
    }
    return memo
  }, {}),
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'),
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tickLensAddress = CHAIN_TO_ADDRESSES_MAP[chainId].tickLensAddress
    if (tickLensAddress) {
      memo[chainId] = tickLensAddress
    }
    return memo
  }, {}),
}
export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const swapRouter02Address = CHAIN_TO_ADDRESSES_MAP[chainId].swapRouterAddress
    if (swapRouter02Address) {
      memo[chainId] = swapRouter02Address
    }
    return memo
  }, {}),
}
export const TRADE_ROUTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tradeRouter = CHAIN_TO_ADDRESSES_MAP[chainId].tradeRouter
    if (tradeRouter) {
      memo[chainId] = tradeRouter
    }
    return memo
  }, {}),
}

export const LOCK_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const lockAddress = CHAIN_TO_ADDRESSES_MAP[chainId].lockAddress
    if (lockAddress) {
      memo[chainId] = lockAddress
    }
    return memo
  }, {}),
}

export const VOTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const voterAddress = CHAIN_TO_ADDRESSES_MAP[chainId].voterAddress
    if (voterAddress) {
      memo[chainId] = voterAddress
    }
    return memo
  }, {}),
}

export const MINTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const minterAddress = CHAIN_TO_ADDRESSES_MAP[chainId].minterAddress
    if (minterAddress) {
      memo[chainId] = minterAddress
    }
    return memo
  }, {}),
}

export const ROUGEX_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const rougexTokenAddress = CHAIN_TO_ADDRESSES_MAP[chainId].rougexTokenAddress
    if (rougexTokenAddress) {
      memo[chainId] = rougexTokenAddress
    }
    return memo
  }, {}),
}

export const FAUCET_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const faucetAddress = CHAIN_TO_ADDRESSES_MAP[chainId].faucetAddress
    if (faucetAddress) {
      memo[chainId] = faucetAddress
    }
    return memo
  }, {}),
}
export const TRADEREADER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tradeReaderAddress = CHAIN_TO_ADDRESSES_MAP[chainId].tradeReaderAddress
    if (tradeReaderAddress) {
      memo[chainId] = tradeReaderAddress
    }
    return memo
  }, {}),
}

export const REWARDS_DISTRIBUTOR_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const rewardsDistributorAddress = CHAIN_TO_ADDRESSES_MAP[chainId].rewardsDistributorAddress
    if (rewardsDistributorAddress) {
      memo[chainId] = rewardsDistributorAddress
    }
    return memo
  }, {}),
}

export const ROX_UTILS_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const roxUtilsAddress = CHAIN_TO_ADDRESSES_MAP[chainId].roxUtilsAddress
    if (roxUtilsAddress) {
      memo[chainId] = roxUtilsAddress
    }
    return memo
  }, {}),
}

export const ORDER_BOOK_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const bookAddress = CHAIN_TO_ADDRESSES_MAP[chainId].orderBookAddress
    if (bookAddress) {
      memo[chainId] = bookAddress
    }
    return memo
  }, {}),
}

export const POSN_READER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const bookAddress = CHAIN_TO_ADDRESSES_MAP[chainId].PosnReader
    if (bookAddress) {
      memo[chainId] = bookAddress
    }
    return memo
  }, {}),
}
