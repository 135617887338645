import { darken } from 'polished'
import { forwardRef } from 'react'
import { ChevronDown } from 'react-feather'
import { Button as RebassButton, ButtonProps as ButtonPropsOriginal } from 'rebass/styled-components'
import styled, { DefaultTheme } from 'styled-components/macro'

import { RowBetween } from '../Row'

// eslint-disable-next-line import/no-unused-modules
export { default as LoadingButtonSpinner } from './LoadingButtonSpinner'
import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ReactComponent as CheckIcon } from 'assets/imgs/checked2.svg'
import { ReactComponent as PendingIcon } from 'assets/imgs/pending.svg'
import { ThemedText } from 'theme'

type ButtonProps = Omit<ButtonPropsOriginal, 'css'>

const ButtonOverlay = styled.div`
  background: transparent;
  bottom: 0;
  border-radius: inherit;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 150ms ease background;
  width: 100%;
`

type BaseButtonProps = {
  padding?: string
  height?: string
  width?: string
  $borderRadius?: string
  altDisabledStyle?: boolean
} & ButtonProps

export const BaseButton = styled(RebassButton)<BaseButtonProps>`
  padding: ${({ padding }) => padding ?? '4px 16px'};
  min-height: ${({ height }) => height ?? '48px'};
  width: ${({ width }) => width ?? '100%'};
  color: ${({ theme }) => theme.textPrimary};
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  border-radius: ${({ $borderRadius }) => $borderRadius ?? '8px'};
  outline: none;
  border: 1px solid transparent;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    /* opacity: 50%; */
    cursor: not-allowed;
    pointer-events: none;
  }

  will-change: transform;
  transition: transform 450ms ease;
  transform: perspective(1px) translateZ(0);

  > * {
    user-select: none;
  }

  > a {
    text-decoration: none;
  }
`

export const ButtonPrimary = styled(BaseButton)`
  background: ${({ theme }) => theme.primaryBtn};
  color: ${({ theme }) => theme.white};

  &:hover {
    color: ${({ theme }) => theme.textTabActive};
    background: ${({ theme }) => theme.primaryBtn_hover};
    box-shadow: 0px 0px 52px 0px ${({ theme }) => theme.primaryBtn_hover};

  }
  &:disabled {
    background: ${({ theme }) => theme.primaryBtn_disabled};
    color: ${({ theme }) => theme.textTertiary};
    cursor: not-allowed;
    pointer-events: auto;
    box-shadow: none;
    outline: none;
  }
`

export const ButtonRetry = styled(ButtonPrimary)`
  background: ${({ theme }) => theme.retryBg};
`

export const SmallButtonPrimary = styled(ButtonPrimary)`
  width: auto;
  font-size: 16px;
  padding: ${({ padding }) => padding ?? '8px 12px'};
  border-radius: 12px;
`

const BaseButtonLight = styled(BaseButton)`
  background: ${({ theme }) => theme.accentActionSoft};
  color: ${({ theme }) => theme.accentAction};
  font-size: 20px;
  font-weight: 600;

  &:hover {
    background: ${({ theme, disabled }) => !disabled && theme.accentActionSoft};
  }
  :hover {
    ${ButtonOverlay} {
      background: ${({ theme }) => theme.stateOverlayHover};
    }
  }
  :active {
    ${ButtonOverlay} {
      background: ${({ theme }) => theme.stateOverlayPressed};
    }
  }

  :disabled {
    :hover {
      cursor: auto;
      background: transparent;
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`

export const ButtonGray = styled(BaseButton)`
  background: ${({ theme }) => theme.deprecated_bg1};
  color: ${({ theme }) => theme.textSecondary};
  font-size: 16px;
  font-weight: 500;

  &:hover {
    background: ${({ theme, disabled }) => !disabled && darken(0.05, theme.backgroundInteractive)};
  }
`

export const ButtonSecondary = styled(BaseButton)`
  border: 1px solid ${({ theme }) => theme.secondaryBtn_border};
  color: ${({ theme }) => theme.textPrimary};
  background: transparent;

  &:hover {
    color: ${({ theme }) => theme.secondaryBtn_hover_text};
    background: ${({ theme }) => theme.secondaryBtn_hover_bg};
    border: 1px solid ${({ theme }) => theme.secondaryBtn_hover_bg};
  }
  &:disabled {
    background: ${({ theme }) => theme.primaryBtn_disabled};
    color: ${({ theme }) => theme.textTertiary};
    cursor: not-allowed;
    pointer-events: auto;
    box-shadow: none;
    outline: none;
  }
  a:hover {
    text-decoration: none;
  }
`

const ButtonConfirmedStyle = styled(BaseButton)`
  background: ${({ theme }) => theme.deprecated_bg3};
  color: ${({ theme }) => theme.textPrimary};
  /* border: 1px solid ${({ theme }) => theme.accentSuccess}; */

  &:disabled {
    /* opacity: 50%; */
    background: ${({ theme }) => theme.backgroundInteractive};
    color: ${({ theme }) => theme.textSecondary};
    cursor: auto;
  }
`

const ButtonErrorStyle = styled(BaseButton)`
  background: ${({ theme }) => theme.accentFailure};
  border: 1px solid ${({ theme }) => theme.accentFailure};

  &:hover {
    background: ${({ theme }) => darken(0.05, theme.accentFailure)};
  }
  &:disabled {
    /* opacity: 50%; */
    cursor: auto;
    box-shadow: none;
    background: ${({ theme }) => theme.accentFailure};
    border: 1px solid ${({ theme }) => theme.accentFailure};
  }
`

export const StyledPendingIcon = styled(PendingIcon)`
  width: 16px;
  height: 16px;
`

export function Pending() {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" gap="8px">
      <ThemedText.TextPrimary color="white" fontSize={14}>
        <Trans>Pending</Trans>
      </ThemedText.TextPrimary>
      <StyledPendingIcon />
    </Box>
  )
}

export function ButtonConfirmed({
  confirmed,
  altDisabledStyle,
  ...rest
}: { confirmed?: boolean; altDisabledStyle?: boolean } & ButtonProps) {
  if (confirmed) {
    return <ButtonConfirmedStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} altDisabledStyle={altDisabledStyle} />
  }
}

export function ButtonError({ error, ...rest }: { error?: boolean } & BaseButtonProps) {
  if (error) {
    return <ButtonErrorStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} />
  }
}

export function ButtonDropdown({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonPrimary {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <ChevronDown size={24} />
      </RowBetween>
    </ButtonPrimary>
  )
}

export const ManagerButton = styled(BaseButton)<{ width?: string }>`
  background: transparent;
  border-radius: 8px;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 14px;
  font-weight: 700;
  width: ${({ width }) => (width ? width : 'auto')};
  min-height: 36px;
  padding: 10px 16px;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
`

export const AddButton = styled(ButtonSecondary)`
  font-size: 13px;
  font-weight: 700;
  color: ${({ theme }) => theme.white};
  min-height: 32px;
  width: auto;
  padding: 4px 10px;
  border-radius: 8px;
  border: none;
  background: ${({ theme }) => theme.claimBtn};
  &:hover {
    box-shadow: 0px 0px 52px 0px #1EA7E4;

  }
`

export const BribesButton = styled(ButtonSecondary)`
  font-size: 13px;
  font-weight: 700;
  min-height: 32px;
  width: auto;
  padding: 4px 10px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
`

export const ClaimButton = styled(BaseButton)<{ width?: string; height?: string; fontSize?: string }>`
  height: ${({ height }) => (height ? height : '36px')};
  min-height: ${({ height }) => (height ? height : '36px')};
  width: ${({ width }) => (width ? width : 'fit-content')};
  white-space: nowrap;
  padding: 4px 10px;
  border: none;çç
  color: ${({ theme }) => theme.claimBtn_text};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '14px')};

  &:hover {
    box-shadow: 0px 0px 52px 0px #1EA7E4;

  }
  &:disabled {
    background: ${({ theme }) => theme.primaryBtn_disabled};
    color: ${({ theme }) => theme.textTertiary};
    cursor: not-allowed;
    pointer-events: auto;
    box-shadow: none;
    outline: none;
  }
`

export const TradeButton = styled(ClaimButton)<{ width?: string }>`
  padding: 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.white};
  &:hover {
    color: ${({ theme }) => theme.white};
  }
`

export const CopyCodeButton = styled(BaseButton)<{ width?: string }>`
  height: 40px;
  min-height: 40px;
  width: ${({ width }) => (width ? width : 'fit-content')};
  padding: 12px 10px;
  border: 1px solid ${({ theme }) => theme.referralCodeBorder};
  background: ${({ theme }) => theme.referralCodeBg};
  color: ${({ theme }) => theme.referralCopy};
  font-size: 14px;

  &:hover {
    background: ${({ theme }) => theme.referralCode};
    color: ${({ theme }) => theme.textPrimary};
    box-shadow: 0px 0px 16px 0px ${({ theme }) => theme.referralCodeBorder};
  }
`

export const CopyLinkButton = styled(CopyCodeButton)<{ width?: string }>`
  color: ${({ theme }) => theme.referralCopyLink};
  border: 1px solid ${({ theme }) => theme.referralLinkBg};
  background: ${({ theme }) => theme.referralLinkBg};
`

export const PostButton = styled(CopyCodeButton)<{ width?: string }>`
  color: ${({ theme }) => theme.postText};
  border: 1px solid ${({ theme }) => theme.postBg};
  background: ${({ theme }) => theme.postBg};
  &:hover {
    color: ${({ theme }) => theme.postText};
    background: ${({ theme }) => theme.postBgHover};
  }
`

const ButtonOutlined = styled(ButtonSecondary)`
  border-color: ${({ theme }) => theme.primaryBorder};
  &:hover {
    border: 1px solid;
    border-color: ${({ theme }) => theme.accentAction};
    background: transparent;
  }
`

const ActiveOutlined = styled(ButtonOutlined)`
  border: 1px solid;
  border-color: ${({ theme }) => theme.accentAction};
`

const CheckboxWrapper = styled.div`
  width: 20px;
  padding: 0 10px;
  position: absolute;
  top: 11px;
  right: 15px;
`

export function ButtonRadioChecked({ active = false, children, ...rest }: { active?: boolean } & ButtonProps) {
  if (!active) {
    return (
      <ButtonOutlined $borderRadius="12px" padding="12px 8px" {...rest}>
        <RowBetween>{children}</RowBetween>
      </ButtonOutlined>
    )
  } else {
    return (
      <ActiveOutlined {...rest} padding="12px 8px" $borderRadius="12px">
        <RowBetween>
          {children}
          <CheckboxWrapper>
            <CheckIcon />
          </CheckboxWrapper>
        </RowBetween>
      </ActiveOutlined>
    )
  }
}

export enum ButtonSize {
  small,
  medium,
  large,
}
export enum ButtonEmphasis {
  high,
  promotional,
  highSoft,
  medium,
  low,
  warning,
  destructive,
  failure,
}
interface BaseThemeButtonProps {
  size: ButtonSize
  emphasis: ButtonEmphasis
}

function pickThemeButtonBackgroundColor({ theme, emphasis }: { theme: DefaultTheme; emphasis: ButtonEmphasis }) {
  switch (emphasis) {
    case ButtonEmphasis.high:
      return theme.accentAction
    case ButtonEmphasis.promotional:
      return theme.accentActionSoft
    case ButtonEmphasis.highSoft:
      return theme.accentActionSoft
    case ButtonEmphasis.low:
      return 'transparent'
    case ButtonEmphasis.warning:
      return theme.accentWarningSoft
    case ButtonEmphasis.destructive:
      return theme.accentCritical
    case ButtonEmphasis.failure:
      return theme.accentFailureSoft
    case ButtonEmphasis.medium:
    default:
      return theme.backgroundInteractive
  }
}
function pickThemeButtonFontSize({ size }: { size: ButtonSize }) {
  switch (size) {
    case ButtonSize.large:
      return '20px'
    case ButtonSize.medium:
      return '16px'
    case ButtonSize.small:
      return '14px'
    default:
      return '16px'
  }
}
function pickThemeButtonLineHeight({ size }: { size: ButtonSize }) {
  switch (size) {
    case ButtonSize.large:
      return '24px'
    case ButtonSize.medium:
      return '20px'
    case ButtonSize.small:
      return '16px'
    default:
      return '20px'
  }
}
function pickThemeButtonPadding({ size }: { size: ButtonSize }) {
  switch (size) {
    case ButtonSize.large:
      return '16px'
    case ButtonSize.medium:
      return '10px 12px'
    case ButtonSize.small:
      return '8px'
    default:
      return '10px 12px'
  }
}
function pickThemeButtonSecondaryColor({ theme, emphasis }: { theme: DefaultTheme; emphasis: ButtonEmphasis }) {
  switch (emphasis) {
    case ButtonEmphasis.high:
    case ButtonEmphasis.promotional:
      return theme.accentAction
    case ButtonEmphasis.highSoft:
      return theme.accentAction
    case ButtonEmphasis.low:
      return theme.textSecondary
    case ButtonEmphasis.warning:
      return theme.accentWarning
    case ButtonEmphasis.destructive:
      return theme.accentTextDarkPrimary
    case ButtonEmphasis.failure:
      return theme.accentFailure
    case ButtonEmphasis.medium:
    default:
      return theme.textPrimary
  }
}

const BaseThemeButton = styled.button<BaseThemeButtonProps>`
  align-items: center;
  background: ${pickThemeButtonBackgroundColor};
  border-radius: 16px;
  border: 0;
  color: ${pickThemeButtonSecondaryColor};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: ${pickThemeButtonFontSize};
  font-weight: 600;
  gap: 12px;
  justify-content: center;
  line-height: ${pickThemeButtonLineHeight};
  padding: ${pickThemeButtonPadding};
  position: relative;
  transition: 150ms ease opacity;
  user-select: none;

  :active {
    ${ButtonOverlay} {
      background: ${({ theme }) => theme.stateOverlayPressed};
    }
  }
  :focus {
    ${ButtonOverlay} {
      background: ${({ theme }) => theme.stateOverlayPressed};
    }
  }
  :hover {
    ${ButtonOverlay} {
      background: ${({ theme }) => theme.stateOverlayHover};
    }
  }
  :disabled {
    cursor: default;
    opacity: 0.6;
  }
  :disabled:active,
  :disabled:focus,
  :disabled:hover {
    ${ButtonOverlay} {
      background: transparent;
    }
  }
`

interface ThemeButtonProps extends React.ComponentPropsWithoutRef<'button'>, BaseThemeButtonProps {}
type ThemeButtonRef = HTMLButtonElement

export const ThemeButton = forwardRef<ThemeButtonRef, ThemeButtonProps>(function ThemeButton(
  { children, ...rest },
  ref
) {
  return (
    <BaseThemeButton {...rest} ref={ref}>
      <ButtonOverlay />
      {children}
    </BaseThemeButton>
  )
})

export const ButtonLight = ({ children, ...rest }: BaseButtonProps) => {
  return (
    <BaseButtonLight {...rest}>
      <ButtonOverlay />
      {children}
    </BaseButtonLight>
  )
}
